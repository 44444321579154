@import '~@ng-select/ng-select/themes/default.theme.css';

a {
  color: #526484;
}

.btn-super {
  background: linear-gradient(271.15deg,
      #c7504c -13.42%,
      #d14f7f 13.3%,
      #ba50ac 42.71%,
      #b74fd1 72.87%,
      #8d4cc7 100%) !important;
  color: #fff !important;
}

.username {
  font-weight: 400;
  color: #8094ae;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.btn-super-outline {
  border: solid 1px #ba50ac !important;
  color: #ba50ac !important;
}

.known-customer {
  width: 20px !important;
  height: 20px !important;
  z-index: 1;
  position: absolute;
  left: 30px;
}

.invalid-feedback {
  display: block;
  color: #e85347;
  font-size: 11px;
  font-style: italic;
}

.custom-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 200;
  height: 150px;
  border: 2px dashed #dbdfea;
  border-radius: 5px;
  background: white;
  margin: 10px 0;

  &.hovering {
    border: 2px solid #6576ff;
    color: #dadada !important;
  }

  .file-label {
    font-size: 1.2em;
  }
}

/** Override ng-select **/
.ng-select .ng-select-container {
  border: 1px solid #dbdfea !important;
}

.ng-select .ng-option-selected {
  font-size: 12px !important;
  background-color: #eef0fe !important;
  font-weight: normal !important;
}

.ng-select .ng-option-selected:hover {
  color: #007ff2;
}

.ng-select .ng-option-label {
  font-weight: normal !important;
}

.ng-select .ng-option {
  font-size: 12px !important;
  margin-top: 3px;
}

.ng-select .ng-option:hover {
  background-color: #eef0fe !important;
  color: #007ff2;
}

.ng-select .ng-option-marked {
  background-color: #eef0fe !important;
}

.ng-select .ng-value {
  background-color: #eef0fe !important;
  color: #007ff2 !important;
  border-radius: 3px !important;
}

.ng-select .ng-value-icon.left {
  border-right: none !important;
}

.ng-select .ng-value-icon.left:hover {
  background-color: transparent !important;
}

.ng-select .ng-arrow-wrapper {
  display: none;
}

.ng-placeholder {
  font-size: 0.8125rem;
  font-weight: 400;
  color: #b6c6e3 !important;
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-action {
  color: #c636b2;
  background-color: #f1eef3;

  :hover {
    color: #c636b2;
    background-color: #f1eef3;
  }

  ::after {
    color: #c636b2;
    background-color: #f1eef3;
  }
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #c636b2;
  background-color: #c636b2;
}